import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import profileBasic from '@/store/modules/profile/profileBasic'
import verifyEmail from '@/store/modules/authentication/verifyEmail'
import lessonRegistrationBasic from '@/store/modules/lesson/lessonRegistrationBasic'
import lesson from '@/store/modules/lesson/lesson'
import createPersistedState from 'vuex-persistedstate'
import payBasic from '@/store/modules/student/pay/payBasic'
import userBasic from '@/store/modules/user/userBasic'
import lessonRegistration from '@/store/modules/teacher/lessonRegistration'
import payment from '@/store/modules/payment/payment'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    profileBasic,
    lessonRegistrationBasic,
    lesson,
    verifyEmail,
    payBasic,
    userBasic,
    lessonRegistration,
    payment,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
})
