export default [
  // ----------------------------------------------------------------------
  // 講師プロフィール等
  // ----------------------------------------------------------------------
  {
    path: '/teacher/dashboad',
    name: 'teacher-dashboad',
    component: () => import('@/pages/teacher/dashboad/MainDashBoad.vue'),
    meta: {
      requireAuth: true,
      read: ['teacher'],
    },
  },
  {
    path: '/teacher/lesson',
    name: 'teacher-lesson',
    component: () => import('@/pages/teacher/lessonRegistration/LessonRegistration.vue'),
    meta: {
      requireAuth: true,
      read: ['teacher'],
    },
  },
  {
    path: '/teacher/lessonregistration-list',
    name: 'teacher-lessonregistration-list',
    component: () => import('@/pages/teacher/lessonRegistration/LessonRegistrationList.vue'),
    meta: {
      requireAuth: true,
      read: ['teacher'],
    },
  },
  {
    path: '/teacher/lessonregistration-edit',
    name: 'teacher-lessonregistration-edit',
    component: () => import('@/pages/teacher/lessonRegistration/LessonRegistrationEdit.vue'),
    meta: {
      requireAuth: true,
      read: ['teacher'],
    },
  },
  // ----------------------------------------------------------------------
  {
    path: '/teacher-profile',
    name: 'teacher-profile',
    component: () => import('@/pages/teacher/profile/ProfileConfirm.vue'),
  },
  {
    path: '/teacher-profile-edit',
    name: 'teacher-profile-edit',
    component: () => import('@/pages/teacher/profile/ProfileEdit.vue'),
  },
  {
    path: '/teacher-lessonregistration',
    name: 'teacher-lessonregistration',
    component: () => import('@/pages/teacher/lessonRegistration/LessonRegistrationlConfirm.vue'),
  },
]
