const state = {
  coin: 0,
  uid: '',
  mail: '',
  userType: '',
  userTypeList: [],
  userNickName: '',
  studentID: '',
  jobSeekerID: '',
  teacherID: '',
  companyID: '',
  adminID: '',
  groupID: '',
  isLogin: false,
  avatar: '',
  userDic: {},
}
const getters = {
  coin: state => state.coin,
  uid: state => state.uid,
  mail: state => state.mail,
  userType: state => state.userType,
  userTypeList: state => state.userTypeList,
  userNickName: state => state.userNickName,
  studentID: state => state.studentID,
  jobSeekerID: state => state.jobSeekerID,
  teacherID: state => state.teacherID,
  companyID: state => state.companyID,
  adminID: state => state.adminID,
  groupID: state => state.groupID,
  isLogin: state => state.isLogin,
  avatar: state => state.avatar,
  userDic: state => state.userDic,
}
const mutations = {
  updateCoin(state, payload) {
    state.coin = payload
  },
  updateUid(state, payload) {
    state.uid = payload
  },
  updateMail(state, payload) {
    state.mail = payload
  },
  updateUserType(state, payload) {
    state.userType = payload
  },
  updateUserTypeList(state, payload) {
    state.userTypeList = payload
  },
  updateUserNickName(state, payload) {
    state.userNickName = payload
  },
  updateStudentID(state, payload) {
    state.studentID = payload
  },
  updateJobSeekerID(state, payload) {
    state.jobSeekerID = payload
  },
  updateTeacherID(state, payload) {
    state.teacherID = payload
  },
  updateCompanyID(state, payload) {
    state.companyID = payload
  },
  updateAdminID(state, payload) {
    state.adminID = payload
  },
  updateGroupID(state, payload) {
    state.groupID = payload
  },
  updateIsLogin(state, payload) {
    state.isLogin = payload
  },
  updateAvatar(state, payload) {
    state.avatar = payload
  },
  updateUserDic(state, payload) {
    state.userDic = payload
  },
  resetAll(state) {
    state.coin = 0
    state.uid = ''
    state.mail = ''
    state.userType = ''
    state.userNickName = ''
    state.studentID = ''
    state.teacherID = ''
    state.companyID = ''
    state.adminID = ''
    state.isLogin = false
    state.avatar = ''
    state.userDic = {}
    state.adminID = ''
  },
}
const actions = {
  updateCoin({ commit }, payload) {
    commit('updateCoin', payload)
  },
  updateUid({ commit }, payload) {
    commit('updateUid', payload)
  },
  updateMail({ commit }, payload) {
    commit('updateMail', payload)
  },
  updateUserType({ commit }, payload) {
    commit('updateUserType', payload)
  },
  updateUserTypeList({ commit }, payload) {
    commit('updateUserTypeList', payload)
  },
  updateUserNickName({ commit }, payload) {
    commit('updateUserNickName', payload)
  },
  updateStudentID({ commit }, payload) {
    commit('updateStudentID', payload)
  },
  updateJobSeekerID({ commit }, payload) {
    commit('updateJobSeekerID', payload)
  },
  updateTeacherID({ commit }, payload) {
    commit('updateTeacherID', payload)
  },
  updateCompanyID({ commit }, payload) {
    commit('updateCompanyID', payload)
  },
  updateAdminID({ commit }, payload) {
    commit('updateAdminID', payload)
  },
  updateGroupID({ commit }, payload) {
    commit('updateGroupID', payload)
  },
  updateIsLogin({ commit }, payload) {
    commit('updateIsLogin', payload)
  },
  updateAvatar({ commit }, payload) {
    commit('updateAvatar', payload)
  },
  updateUserDic({ commit }, payload) {
    commit('updateUserDic', payload)
  },
  resetAll({ commit }) {
    commit('resetAll')
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
