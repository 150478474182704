export default [
  // ----------------------------------------------------------------------
  // データ関連
  // ----------------------------------------------------------------------
  {
    path: '/datacleaning1',
    name: 'datacleaning1',
    component: () => import('@/pages/data/cleaning/DataCleaning1.vue'),
  },
]
