export default [
  {
    path: '/verifyemail',
    name: 'verifyemail',
    component: () => import('@/components/authentication/VerifyEmail.vue'),
  },
  {
    path: '/sendmail',
    name: 'sendmail',
    component: () => import('@/pages/authentication/SendMail.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/pages/authentication/Forget.vue'),
    meta: {
      layout: 'full',
    },
  },
]
