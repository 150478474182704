// -------------------------------------------------------------
//  test 環境
// -------------------------------------------------------------
export default {
  apiKey: 'AIzaSyCVgcJx2NlSAu3Cr5xEn4Vx6OaNBkMWWZ4',
  authDomain: 'be-creation-salon.firebaseapp.com',
  projectId: 'be-creation-salon',
  storageBucket: 'be-creation-salon.appspot.com',
  messagingSenderId: '116023880255',
  appId: '1:116023880255:web:511b115c94ce6ba6c4268a',
  measurementId: 'G-MNGCJ6DXGM',
}
// -------------------------------------------------------------
