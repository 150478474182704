import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { isUserLoggedIn } from '@/auth/utils'
import store from '@/store'
import pages from './routes/pages'
import others from './routes/others'
import authentication from './routes/authentication'
import studentbasic from './routes/student/basic'
import teacherbasic from './routes/teacher/basic'
import adminbasic from './routes/admin/basic'
import databasic from './routes/data/basic'
import metabasic from './routes/meta/basic'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'top' } },
    ...pages,
    ...others,
    ...authentication,
    ...studentbasic,
    ...teacherbasic,
    ...adminbasic,
    ...databasic,
    ...metabasic,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!isLoggedIn && to.meta.requireAuth) return next({ name: 'login' })
  const { userType } = store.state.userBasic
  if (to.meta.read && !to.meta.read.includes(userType)) return next({ name: 'not-authorized' })
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
