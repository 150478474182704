const state = {
  isShowModal: false,
}
const getters = {
  isShowModal: state => state.isShowModal,
}
const mutations = {
  updateIsShowModal(state, payload) {
    state.isShowModal = payload
  },
}
const actions = {
  updateIsShowModal({ commit }, payload) {
    commit('updateIsShowModal', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
