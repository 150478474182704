export default [
  // ----------------------------------------------------------------------
  // ユーザメタ情報
  // ----------------------------------------------------------------------
  {
    path: '/user-account',
    name: 'user-account',
    component: () => import('@/pages/meta/MetaEdit.vue'),
  },
]
