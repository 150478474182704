// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  // return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return store.state.userBasic.uid !== ''
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return { name: 'admin-dashboad' }
  if (userRole === 'student') return { name: 'student-dashboad' }
  if (userRole === 'teacher') return { name: 'teacher-dashboad' }
  return { name: 'student-dashboad' }
}
