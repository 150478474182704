export default [
  {
    path: '/lesson/list',
    name: 'lesson-list',
    component: () => import('@/pages/basic/lesson/LessonList.vue'),
  },
  {
    path: '/lesson/detail',
    name: 'lesson-detail',
    component: () => import('@/pages/basic/lesson/LessonDetail.vue'),
  },
  {
    path: '/lesson/display',
    name: 'lesson-display',
    component: () => import('@/pages/basic/lesson/LessonDisplay.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/pages/basic/cart/Cart.vue'),
  },
  {
    path: '/cart/payment',
    name: 'cart-payment',
    component: () => import('@/pages/basic/cart/CartConfirm.vue'),
  },
  {
    path: '/',
    name: 'top',
    resource: 'Auth',
    action: 'read',
    component: () => import('@/pages/authentication/Login.vue'),
    // component: () => import('@/pages/basic/brand/Top.vue'),
  },
  // {
  //   path: '/',
  //   name: 'top',
  //   meta: {
  //     layout: 'full',
  //   },
  //   component: () => import('@/pages/basic/brand/TopClosed.vue'),
  // },
  // ------------------------------------------------------------------------
  {
    path: '/lesson-apply-confirm',
    name: 'lesson-apply-confirm',
    component: () => import('@/pages/basic/lesson/LessonApplyConfirm.vue'),
  },
  {
    path: '/lesson-apply-thanks',
    name: 'lesson-apply-thanks',
    component: () => import('@/pages/student/lessonApply/LessonApplyThanks.vue'),
  },
  {
    path: '/test-msg',
    name: 'test-msg',
    component: () => import('@/pages/test/MessageTest.vue'),
  },
  {
    path: '/search-event',
    name: 'search-event',
    component: () => import('@/pages/basic/searchEvent/SearchEvents.vue'),
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/pages/basic/about/Company.vue'),
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import('@/pages/basic/about/Introduction.vue'),
  },
  {
    path: '/philosophy',
    name: 'philosophy',
    component: () => import('@/pages/basic/about/Ideal.vue'),
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import('@/pages/basic/about/Recruit.vue'),
  },
  {
    path: '/about-study-platform',
    name: 'about-study-platform',
    component: () => import('@/pages/basic/about/StudyPF.vue'),
  },
  {
    path: '/about-work-platform',
    name: 'about-work-platform',
    component: () => import('@/pages/basic/about/WorkPF.vue'),
  },
  {
    path: '/about-dashboad',
    name: 'about-dashboad',
    component: () => import('@/pages/basic/about/DashBoad.vue'),
  },
  // メニューバーなどのカスタマイズ
  {
    path: '/theme-customize',
    name: 'theme-customize',
    component: () => import('@/pages/admin/ThemeCustomize.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    resource: 'Auth',
    action: 'read',
    component: () => import('@/pages/basic/faq/FAQ.vue'),
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    resource: 'Auth',
    action: 'read',
    component: () => import('@/pages/basic/tutorial/Tutorial.vue'),
  },
  {
    path: '/service',
    name: 'service',
    resource: 'Auth',
    action: 'read',
    component: () => import('@/pages/basic/about/Service.vue'),
  },
  {
    path: '/commercial-law',
    name: 'commercial-law',
    resource: 'Auth',
    action: 'read',
    component: () => import('@/pages/basic/term/Merchant.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    resource: 'Auth',
    action: 'read',
    component: () => import('@/pages/basic/term/Privacy.vue'),
  },
  {
    path: '/student/terms',
    name: 'student-terms',
    resource: 'Auth',
    action: 'read',
    component: () => import('@/pages/basic/term/StudentRule.vue'),
  },
]
