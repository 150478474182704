const state = {
  cartList: [],
}
const getters = {
  cartList: state => state.cartList,
}
const mutations = {
  pushItemToCartList(state, payload) {
    if (!state.cartList.includes(payload)) state.cartList.push(payload)
  },
  updateCartList(state, payload) {
    state.cartList = payload
  },
}
const actions = {
  pushItemToCartList({ commit }, payload) {
    commit('pushItemToCartList', payload)
  },
  updateCartList({ commit }, payload) {
    commit('updateCartList', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
