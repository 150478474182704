export default [
  // ----------------------------------------------------------------------
  // 受講生プロフィール等
  // ----------------------------------------------------------------------
  {
    path: '/student/profile',
    name: 'student-profile',
    component: () => import('@/pages/student/profile/ProfileConfirm.vue'),
    meta: {
      requireAuth: true,
      read: ['student'],
    },
  },
  {
    path: '/student/profile-edit',
    name: 'student-profile-edit',
    component: () => import('@/pages/student/profile/ProfileEdit.vue'),
    meta: {
      requireAuth: true,
      read: ['student'],
    },
  },
  {
    path: '/student/dashboad',
    name: 'student-dashboad',
    component: () => import('@/pages/student/dashboad/BasicDashboadConfirm.vue'),
    meta: {
      requireAuth: true,
      read: ['student'],
    },
  },
  {
    path: '/student/lesson/detail',
    name: 'student-lesson-detail',
    component: () => import('@/pages/student/lesson/LessonDetail.vue'),
    meta: {
      requireAuth: true,
      read: ['student'],
    },
  },
  // ----------------------------------------------------------------------
  {
    path: '/student-basicsetting',
    name: 'student-basicsetting',
    component: () => import('@/pages/student/basicSetting/BasicSettingConfirm.vue'),
  },
  {
    path: '/student-basicsetting-edit',
    name: 'student-basicsetting-edit',
    component: () => import('@/pages/student/basicSetting/BasicSettingEdit.vue'),
  },
  {
    path: '/student-scopesetting',
    name: 'student-scopesetting',
    component: () => import('@/pages/student/scopeSetting/ScopeSettingConfirm.vue'),
  },
  {
    path: '/student-scopesetting-edit',
    name: 'student-scopesetting-edit',
    component: () => import('@/pages/student/scopeSetting/ScopeSettingEdit.vue'),
  },
  {
    path: '/student-pay-list',
    name: 'student-pay-list',
    component: () => import('@/pages/student/pay/PayList.vue'),
  },
  {
    path: '/student-pay-edit',
    name: 'student-pay-edit',
    component: () => import('@/pages/student/pay/PayEdit.vue'),
  },
  {
    path: '/student-pay',
    name: 'student-pay',
    component: () => import('@/pages/student/pay/PayConfirm.vue'),
  },
  {
    path: '/student-lesson-list',
    name: 'student-lesson-list',
    component: () => import('@/pages/student/lessonApply/LessonApplyList.vue'),
  },
  {
    path: '/student-lesson-done-list',
    name: 'student-lesson-done-list',
    component: () => import('@/pages/student/lessonApply/LessonDoneList.vue'),
  },
  {
    path: '/student/dashboad-main',
    name: 'student-dashboad-main',
    component: () => import('@/pages/student/dashboad/MainDashBoad.vue'),
  },
  {
    path: '/student-account',
    name: 'student-account',
    component: () => import('@/pages/student/account/AccountEdit.vue'),
  },
]
