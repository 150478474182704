export default [
  // ----------------------------------------------------------------------
  // 運営側
  // ----------------------------------------------------------------------
  {
    path: '/admin/dashboad',
    name: 'admin-dashboad',
    component: () => import('@/pages/admin/dashboad/MainDashBoad.vue'),
    meta: {
      requireAuth: true,
      read: ['admin'],
    },
  },
  {
    path: '/admin/student/list',
    name: 'admin-student-list',
    component: () => import('@/pages/admin/student/StudentList.vue'),
    meta: {
      requireAuth: true,
      read: ['admin'],
    },
  },
]
